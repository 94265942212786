html, body {
  height: 100%;
}

.App {
  text-align: center;
}

.App .App-Content {
  min-height: 50vh;
}

.App-Privacy, .App-TermsAndConditions {
  text-align: left;
  padding-top: 1rem;  
}

.App-logo {
  height: 8vmin;
  pointer-events: none;
  padding: 10px;
  max-width: 200px;
}

.App-Footer {
  margin-top:200px;
  background-color: #282c34;
  min-height: 10vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.App-Header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.App-link {
  color: #61dafb;
}


nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 5vh;
  color: #fff;
}

.Nav-links {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-decoration: none;
  list-style: none;
}


/* 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} 

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/
