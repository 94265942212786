@import '../../Assets/css/mixins';

nav {
    background-image: linear-gradient(to right, white, $lightgreen, $darkgreen);
}


.welcome-text {
    text-align: left;
}

.welcome-text a{
    text-transform: none;
    font-weight: bold;
    color: $medium_dark_green;
}

.about-text {
    text-align: left;
}

.about-text a{
    text-transform: none;
    font-weight: bold;
    color: $medium_dark_green;
}