.gerx-landing-page-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 40px;

  .menu-button {
    display: flex;
    flex-direction: column;

    .button-body {
      color: lightgrey;
    }
  }
}