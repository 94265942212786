@import '../../../Assets/css/mixins';

.gerx-menubar {
    display: flex;
    flex-direction: row;
    width: 100%;

    .navbar {
        width: 100%;
        background-image: linear-gradient(to right, white, $lightgreen, $darkgreen);

        .nav-link {
            color: #e0e0e0;
            
            &:active {
                color: white;
            }

            &:hover {
                color: white;
            }
        }

        .btn-link {
            font-size: small;
        }
    }
}


.navbar-nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .nav-item {
        &:not(:last-child) {
            margin-right: 8px;
        }

        .btn-link {
            width: 72px;
            font-size: small;
        }
    }
}