.new-statistics {
    margin-top:20px;
    height: 105%;
    display: flex;
    flex-direction: column;
    // background-color: #ebf1f5;

    .charts {
        display: flex;
        justify-content: space-evenly;
        margin: 0 20px;
        height: 100%;
        min-height: 768px;
        
        // background-color: white;
        margin: 1% 3%;
        display: flex;
        justify-content: space-around;
        border-radius: 4px;
    }


    #geoChart2 {
    width: 100%;
    max-height: 540px;

        div {
            position: static !important;        
        }
    }

    #tableChart2 {
        width: 100%;
        max-height: 540px;
    
        div {
            position: static !important;        
        }

    }

}
